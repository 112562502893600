<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-scrollbar height="500px">
            <div class="tree_title">
              <p class="first_class_tittle">项目导航</p>
            </div>
            <div class="block tree_box">
              <el-input v-model="filterText" placeholder="关键字" />
              <el-tree
                ref="tree"
                v-loading="treeLoading"
                :accordion="true"
                :data="treeData"
                :default-expanded-keys="defaultExpandedKeys"
                :expand-on-click-node="true"
                :filter-node-method="filterNode"
                :highlight-current="true"
                class="filter-tree"
                node-key="nodeKey"
                @node-click="nodeClick"
              >
                <template #default="{ node, data }">
                  <span class="custom-tree-node">
                    <span>{{ node.label }}</span>
                    <span>
                      <div class="tag-group">
                        <el-tag v-for="tag in data.tags" :key="tag.number" :type="tag.type" effect="dark" size="mini">
                          {{ tag.number }}
                        </el-tag>
                      </div>
                    </span>
                  </span>
                </template>
              </el-tree>
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="18">
          <div class="table_title">
            <p class="first_class_tittle">任务列表</p>
          </div>
          <div class="table_box">
            <el-scrollbar height="500px">
              <el-table :data="taskData.content" border header-align="center" highlight-current-row size="mini" stripe>
                <el-table-column header-align="center" label="序号" prop="id" type="index" width="50" />
                <el-table-column header-align="center" label="任务号" prop="workNumber" width="80" />
                <el-table-column header-align="center" label="开始时间" prop="beginTime" width="140" />
                <el-table-column header-align="center" label="结束时间" prop="endTime" width="140" />
                <el-table-column header-align="center" label="运行时间" prop="runTime" width="80" />
                <el-table-column header-align="center" label="运行结果" prop="taskResult" show-overflow-tooltip width="120" />
                <el-table-column header-align="center" label="告警规则" prop="alarmRuleNumber" width="80" />

                <el-table-column align="center" header-align="center" label="校验结果" prop="isPassed" width="80">
                  <template #default="scope">
                    <el-tag v-if="scope.row.isPassed" effect="dark" size="mini" type="success"> 通过</el-tag>
                    <el-tag v-else effect="dark" size="mini" type="danger"> 未通过</el-tag>
                  </template>
                </el-table-column>
                <el-table-column header-align="center" label="问题状态" prop="problemStatus" width="80">
                  <template #default="scope">
                    <el-tag v-if="scope.row.isGenerateQualityIssues" effect="dark" size="mini" type="success"> 已生成</el-tag>
                    <el-tag v-else effect="dark" size="mini" type=""> 未生成</el-tag>
                  </template>
                </el-table-column>

                <el-table-column header-align="center" label="操作">
                  <template #default="scope">
                    <el-button circle size="mini" title="查看告警规则" type="primary" @click="onClickAlarmRule(scope.row)">
                      <el-icon>
                        <i-bell-filled />
                      </el-icon>
                    </el-button>

                    <el-button
                      :disabled="scope.row.isPassed ? true : scope.row.isGenerateQualityIssues ? true : false"
                      circle
                      size="mini"
                      title="生成质量问题"
                      type="primary"
                      @click="generateProblem(scope.row, scope.$index)"
                    >
                      <el-icon>
                        <i-first-aid-kit />
                      </el-icon>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
    <TaskAlarmRuleDialog ref="taskAlarmRule" :isOperation="true" @treeDataUpdate="treeDataUpdate" />
    <ProblemAdd ref="addProblem" title="问题生成" @problemAddCallback="addProblemCallback" />
  </div>
</template>

<script>
import BreadCrumb from '/src/components/BreadCrumb'
import { alarmFormSelected } from '@/api/project/work'
import { myAlarmTree } from '@/api/system/alert'
import TaskAlarmRuleDialog from '../../../components/TaskAlarmRuleDialog'
import ProblemAdd from '../../quality/problem/list/index/components/problem-add'
import { mapGetters } from 'vuex'

export default {
  name: 'MyAlert',
  components: {
    BreadCrumb,
    TaskAlarmRuleDialog,
    ProblemAdd,
  },
  data() {
    return {
      selectedData: {},
      treeData: {},
      taskData: {
        content: [],
      },
      treeLoading: true,
      filterText: '',
      defaultExpandedKeys: [],
      clickTask: {
        index: null,
      },
    }
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  mounted() {
    this.loadTreeData(this.treeCallback)
    this.loadSelectedData()
  },
  methods: {
    async loadSelectedData() {
      const { data, code } = await alarmFormSelected()
      if (code === 200) this.selectedData = data
    },
    treeCallback(data) {
      this.treeData = data
      this.treeLoading = false
      let nodeKey = ''
      if (data && data[0]) {
        nodeKey = data[0]
        this.defaultExpandedKeys.push(data[0])
      }
      this.$nextTick(function () {
        this.$refs.tree.setCurrentKey(nodeKey, true)
        const currentNode = this.$refs.tree.getCurrentNode()
        console.log(currentNode)
        this.$nextTick(() => {
          document.querySelector('.el-tree-node').click()
        })
      })
    },
    async loadTreeData(callback) {
      const { data, code } = await myAlarmTree()
      if (code === 200) callback(data)
    },
    nodeClick(node) {
      //console.log(node)
      let task = []
      if (node.mark === 'project') {
        node.children.map(item =>
          item.children.map(t => {
            task.push(t)
          }),
        )
      } else if (node.mark === 'work') {
        task = node.children.map(item => item)
      } else if (node.mark === 'task') {
        task.push(node)
      }
      this.generateTaskData(task)
    },
    generateTaskData(task) {
      const that = this
      let taskData = []
      task.forEach(function (item, index) {
        taskData.push({
          id: index + 1,
          taskId: item.id,
          workId: item.reality.workId,
          workNumber: item.label,
          beginTime: item.reality.beginTime,
          endTime: item.reality.endTime,
          alarmRuleNumber: item.prop.length + `(个)`,
          problemStatus: item.isQualityIssues ? '已生成' : '未生成',
          isPassed: item.prop.map(m => m.isPass).some(s => s == 1),
          taskResult: item.prop[0].result,
          runTime: that.$publics.getDateDiff(item.reality.beginTime, item.reality.endTime),
          nodeKey: item.nodeKey,
          isGenerateQualityIssues: item.reality.isGenerateQualityIssues,
          ...item,
        })
      })
      this.taskData.content = taskData.sort((a, b) => this.$publics.compareTime(a.endTime, b.endTime))
    },
    generateProblem(row, index) {
      //console.log(row)
      const workName = this.$refs.tree.getNode(row.nodeKey).parent.data.label
      this.$refs.addProblem.isDisplay = true
      this.$refs.addProblem.problem = {
        issueDesc: `${workName} 设置 ${row.prop.length} 个告警规则,其中 ${row.prop.filter(f => f.isPass === 1).length} 个告警规则通过校验,${
          row.prop.filter(f => f.isPass === 0).length
        } 个规则未通过校验,详情请查看告警详情!`,
        issueSource: 1,
        workId: row.workId,
        taskId: row.taskId,
      }
      this.clickTask = { index }
    },
    addProblemCallback(status) {
      if (status) this.taskData.content[this.clickTask.index].isGenerateQualityIssues = 1
    },
    onClickAlarmRule(task) {
      this.$refs.taskAlarmRule.dialogIsShow = true
      this.$refs.taskAlarmRule.loadPageDataByTreeId(task.taskId)
    },
    treeDataUpdate() {
      this.loadTreeData(this.treeCallback)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
  },
}
</script>

<style scoped>
.tree_box {
  padding: 5px;
}

.tree_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.table_title {
  width: 100%;
  padding: 5px;
}

.tag-group span {
  margin: 0 1px;
}
</style>
